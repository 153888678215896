.text
  display inline-block

  &:focus
  &:hover
    color var(--eds-color-text-success)
    cursor pointer

.copyButton
  margin-left 4px

.copied svg
  color var(--eds-color-text-success)

.showOnHover
  .copyButton
    opacity 0

  &:focus
  &:hover
    .copyButton
      opacity 1