html
body
  margin 0

// Fix styling conflict between eds and console platform causing links to show dark in the side bar
#console-integrated-platform #nav-root div > a > span
  color #b2b2b2 !important
  font-size 16px
  line-height 24px
  font-weight 500

// Fix styling conflict between eds and console platform sidebar tooltip
#console-integrated-platform #nav-root nav ul span
  color white !important

.eds-tooltip.eds--dark
  color white !important

// Because we're using custom table in the app here is some on-row-hover styles
table.hover tbody tr:hover
  background-color #e5e5e5 !important
  cursor pointer

// There was a double diamond, this removes it
[data-popper-placement='right']::after
  display none

.no-padding
  padding 0

.no-margin
  margin-top 0 !important

.errorStyle
  color #AD391F

.infoCircle
  vertical-align sub
  margin-left 12px

.word-break
  word-break break-all

.modal-1200 > .eds-modal
  width 1200px