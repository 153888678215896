.underlineStyle
  display inline-block
  height 40px
  border-bottom 2px solid
  margin-left 36px
  cursor pointer

.pageStyle
  display inline-block
  color #757575
  margin-left 36px
  cursor pointer

.caretLeft
  vertical-align sub
  margin-left 48px

.caretRight
  vertical-align sub
  margin-left 36px
  margin-right 48px

.selectRows
  margin-left 8px
  width 80px

  &:before
    border-bottom none !important
