.saveButton
  background-color #00a664

.edit
  background-color #EFEFEF

.ghostButton
  @extends .edit
  vertical-align bottom

.closeIcon
  color #fe0000
  height 16px