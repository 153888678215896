.TablePage
  padding 48px

.TablePageGrid
  display grid
  grid-auto-flow column
  column-gap 24px
  grid-template-columns 400px 1fr 180px
  margin-bottom 24px

.resizeTable
  width 100%
  table-layout fixed

.resizeHandle
  cursor col-resize
  height 25px
  float right
  width 10px
  z-index 1
  border-right 2px solid transparent
  border-color #517ea5

.ellipsisStyle
  white-space nowrap
  overflow hidden
  text-overflow ellipsis

.tableSearchFilter
  margin-top 0 !important

.tableNoResultMessage
  background-color white
  height 510px
  border 1px solid #CCC
  text-align center
  padding-top 231px

.tableLayout
  width 100%
  cursor pointer
  table-layout fixed
