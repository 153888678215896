.statusIcon
  height 16px
  vertical-align bottom
  color var(--eds-color-text-default)

.success
  color var(--eds-color-text-success)

.danger
  color var(--eds-color-text-danger)

.warning
  color var(--eds-color-yellow-40)