.sink
  margin-bottom 24px
  align-items center

  &:hover
    background-color var(--eds-color-background-secondary) !important

.revoke
  transform scaleX(-1)
  height 16px
  width 16px
