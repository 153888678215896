$color-red = #fe0000

.buttonStyle
  float right
  width 100px

.deleteColor
  color $color-red

.deleteButton
.deleteButton:hover
  @extends .buttonStyle
  border-color $color-red