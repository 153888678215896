.stream
  background-color var(--eds-color-background-secondary)
  border 1px solid var(--eds-color-border-primary)
  border-radius 8px
  margin-bottom 24px
  padding 16px

  > section
    background-color var(--eds-color-background-default)
    border-radius 8px
    margin 1em
    padding 1em

  h4
    display inline-block

  h5
    display block

.controls
  float right
  display flex

  button
    margin-right 24px

.edit
  background-color #EFEFEF
