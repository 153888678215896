.dropdownStyle
  display flex
  padding 14px 10px
  background-color white
  border 1px solid #CCC
  border-radius 4px
  width 100%
  height 54px

.selectAccess
  background-color white
  border 1px solid #CCC
  border-radius 4px
  padding-right 10px
  height 54px

.caretWidth
  width 42px